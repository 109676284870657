.loader {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100vw;
}

.loader__image {
    height: 6rem;
    width: 6rem;
}