.text-input {
    border: 1px solid #cacccd;
    height: 48px;
    font-size: $font-size-large;
    font-weight: 300;
    padding: $s-size;
    outline: none;
}

.select {
    @extend .text-input;
}

.text-area {
    @extend .text-input;
    height: 10rem;
}