.input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: $m-size;

    @media (min-width: $desktop-breakpoint) {
        flex-direction: row;
        margin-bottom: $l-size;
    }
}

.input-group__container {
    display: flex;
    justify-content: left;

    @media (min-width: $desktop-breakpoint) {
        justify-content: center;
    }
}

.input-group__item {
    margin-bottom: $s-size;

    @media (min-width: $desktop-breakpoint) {
        margin: 0 $s-size 0 0;
    }
}