.page-header {
    background: $off-white;
    margin-bottom: $l-size;
    padding: $l-size 0;
}

.page-header__actions {
    margin-top: $m-size;
    text-align: center;
}

.page-header__title {
    font-weight: 300;
    margin: 0;
    text-align: center;

    span {
        font-weight: bold;
    }
}