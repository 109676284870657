.button {
    background: $dark-green;
    border: none;
    color: $white;
    display: inline-block;
    font-size: $font-size-large;
    font-weight: 300;
    line-height: 1;
    padding: $m-size;
    text-decoration: none;
    transition: background 0.2s ease-in-out;
    outline: none;

    &:hover {
        background: darken($dark-green, 10%);
    }
}

.button--link {
    background: none;

    &:hover {
        background: none;
        color: darken($white, 10%);
    }
}

.button--secondary {
    background: $red;

    &:hover {
        background: darken($red, 10%);
    }
}