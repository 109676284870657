// Colors
$light-green: #BCD8B7;
$dark-green: #6BA292;
$grey: #93827F;
$red: #AB4E68;
$white: #E0D2C3;
$off-white: lighten($light-green, 15%);

// Font Size
$font-size-large: 1.8rem;
$font-size-small: 1.4rem;

// Spacing
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$desktop-breakpoint: 45rem;